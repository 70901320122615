$primary: #c83132; /* MAIN COLOR */
$secondary: #c83132; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #c83132;

// @import url('https://fonts.googleapis.com/css?family=Aldrich|Black+Ops+One|Share+Tech+Mono');
@import url('https://fonts.googleapis.com/css?family=Michroma');
@font-face {
    font-family: 'Airstream';
    src: url('fonts/Airstream.eot');
    src: url('fonts/Airstream.eot?#iefix') format('embedded-opentype'),
        url('fonts/Airstream.woff2') format('woff2'),
        url('fonts/Airstream.woff') format('woff'),
        url('fonts/Airstream.ttf') format('truetype'),
        url('fonts/Airstream.svg#Airstream') format('svg');
    font-weight: normal;
    font-style: normal;
}


.modal{
	z-index: 5000;
}

.modal-open{
	overflow: auto;
}

.top-pad{
	margin-top: 135px;
	font-size: 1.3em;
	color: $blk;
	
	h1,h2,h3,h4,h5,h6 {
		color: $blk;
		font-size: 1.5em;
	}
	
	@media(max-width:767px){
		margin-top: 35px;
		font-size: 1em;
	}
}

.flash{
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.fade {
	background: rgba(31,31,31,.5) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid #0065dd;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}


@mixin btn{
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	// font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;

	&:hover{
		background: #757575;
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

.btn-default{
	@include btn;
}

.modal-dialog{
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close{
		display: none;
	}

	.modal-content{
		color: $primary;
		// color: #333;

		h2{
			text-align:center;
		}
	}

	input{
		border: 1px solid #777;
		text-align: center;
		color: #333;
	}

    input#username, input#password{
    	// font-family: 'Share Tech Mono', monospace !important;
    	font-family: 'Michroma', sans-serif;

  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button{
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			// text-shadow: 0px 0px 3px $blk;
			border:none;
		}

		// &:focus {
		// 	background: #654b2d !important;
		// 	color: lighten($wht, 10%) !important;
		// 	box-shadow: 0px 0px 3px lighten($blk,10%) !important;
		// }
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 100%;
		font-size: 18px;
	}

}

.btn{

	&:active {
	box-shadow: none !important;

	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

#myModalLabel {
	color: #fff !important;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

.modal-body {
	// background: $secondary;
	background: #3a3a3a;
}


input#username, input#password {
	// font-family: 'Share Tech Mono', monospace;
	font-family: 'Michroma', sans-serif;
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
	// font-family: 'Black Ops One', cursive;
	font-family: 'Michroma', sans-serif;
  outline: 0 !important;
}


.navbar-brand {
	padding: 0px !important;
}

html {
	-webkit-font-smoothing: antialiased;
} 
 
body {
	// font-family: 'Share Tech Mono', monospace;
	font-family: 'Michroma', sans-serif;
	color: #333;
	font-size: 15px; 
	line-height: 23px;
	// font-weight: 400;	
}

@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }


.page-content, section { overflow: hidden; }

.animated { visibility: hidden; }
.visible { visibility: visible; }

.b-top {
	border-top: 1px solid #ddd;
}

.b-bottom {
	border-bottom: 1px solid #ddd;
}

/*------------------------------------------*/
/*	HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
	font-family: 'Airstream', 'Aldrich', sans-serif;
	color: #222;
	// font-family: 'Lato', sans-serif;
	// font-weight: 400;
	margin-top: 0; 
	margin-bottom: 0;
}

h4 {
	font-size: 30px;
	// line-height: 17px;
	// font-weight: 900;
	margin-bottom: 12px;
}

h4.h4-lg {
	font-size: 25px;
	line-height: 18px;
} 

h3 {
	font-size: 28px;
	line-height: 36px;
	margin-bottom: 20px;
} 

h3.h3-thin {
	font-size: 28px;
	line-height: 36px;
	// font-weight: 500;
}

/*------------------------------------------*/
/*	 PARAGRAPHS
/*------------------------------------------*/

p {
	color: #444;
	font-size: 15px; 
	line-height: 23px;
}

p.p-lg {
	color: #666;
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 20px;
	// font-weight: 300;
} 

/*------------------------------------------*/
/*	 LISTS
/*------------------------------------------*/

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

/*------------------------------------------*/
/*	 LINK SETTINGS
/*------------------------------------------*/

a {
	text-decoration: none;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}


a:hover {
	color: #444;
	outline: none;
	text-decoration: none;
}

a:active{
	outline: none;
	text-decoration: none;
}

a:focus {
	background-color: transparent;
	outline: none;
	text-decoration: none;
}

/*------------------------------------------*/
/*	IMAGE SETTINGS
/*------------------------------------------*/




/*------------------------------------------*/
/*	 BUTTON SETTINGS
/*------------------------------------------*/

.btn {
	background-color: $primary;
	color: #fff;
	font-size: 13px;
	// font-weight: 900;	
	letter-spacing: 1px;
	text-transform: uppercase;
	// border: 2px solid $primary;
	padding: 12px 28px;
	margin-right: 8px;
 	-webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;	
	-webkit-transition: all 350ms ease-in-out;
	-moz-transition: all 350ms ease-in-out;
	-o-transition: all 350ms ease-in-out;
	-ms-transition: all 350ms ease-in-out;
	transition: all 350ms ease-in-out;
}

/*------------------------------------------*/
/*	 Large Button 
/*------------------------------------------*/

.btn.btn-lg {
	font-size: 13px;
	// font-weight: 900;	
	padding: 14px 30px;	
}

/*------------------------------------------*/
/*	 Button Transparent Background
/*------------------------------------------*/

.btn.btn-tra {
	background-color: transparent;
	color: $secondary;
}

.white-color .btn {
	background-color: $secondary;
	color: #fff;
	border-color: $primary;
}

.white-color .btn.btn-tra {
	background-color: transparent;
	color: #fff;
	border-color: #fff;
}

/*------------------------------------------*/
/*	 Button Hover
/*------------------------------------------*/

.btn:hover,
.btn:focus {
	background-color: #7b7b7b;
	color: #fff;
	border-color: #333;
}

.white-color .btn:hover {
	background-color: transparent;
	color: #fff;
	border-color: #fff;
}

.btn.btn-tra:hover,
.btn.btn-tra:focus,
.white-color .btn.btn-tra:hover,
.white-color .btn.btn-tra:focus {
	background-color: #00c1f0;
	color: #fff;
	border-color: #00c1f0;
} 

/*------------------------------------------*/
/*	 SECTION TITLEBAR
/*------------------------------------------*/

.section-title,
.section-title-thin {
	text-align: center;
	margin-bottom: 60px;
}

.section-title h3 {
	font-size: 30px;
	line-height: 30px;
	margin-bottom: 15px;
}

.section-title-thin h3 {
	font-size: 30px;
	line-height: 38px;
	// font-weight: 400;
}

.section-title-thin h4 {
	color: $secondary;
	font-size: 15px;
	line-height: 15px;
	// font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 15px;
}

.section-title p { 
	color: #555;
	font-size: 18px;
	line-height: 26px;
	// font-weight: 400;
	padding: 0 25%;
}

.section-title-thin h3:after,
.section-title p:after {
    display: block;
    content: "";
    height: 1px;
    width: 60px;
	background: $secondary;
    margin: 20px auto 0;
	margin-top: 20px;
    position: relative;
}

/*------------------------------------------*/
/*	 CONTENT COLOR
/*------------------------------------------*/
   
.white-color h2, .white-color h3, 
.white-color h4, .white-color h5, 
.white-color p, .white-color a,
.white-color i, .white-color span {
	color: #fff;
} 

/* ==========================================================================
   03. PRELOAD ANIMATION
   ========================================================================== */
   
.animationload {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 999999; 
}

.loader {
	position: absolute;
	top: 50%; 
	left: 50%; 
	margin: -100px 0 0 -100px;
	width: 172px;
	height: 172px;
	background-image: url('../img/loader.gif');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 72px 72px;
} 

/* ==========================================================================
   04. HEADER & NAVIGATION
   ========================================================================== */
   
.header {
	margin-bottom: 60px;
}

.header.no-bg {
	margin-bottom: 0px;
	background-color: transparent;
}

/*------------------------------------------*/
/*	 LOGO IMAGE    
/*------------------------------------------*/

.navbar-brand {
	height: 60px;


	@media (max-width: 767px) {
		width: 150px;
		height: 30px;
	}
}

/*------------------------------------------*/
/*	 NAVIGATION BAR    
/*------------------------------------------*/

.navbar {	
	padding: 0px;
	margin-bottom: 0;
	-webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
	box-shadow: 0 0 2px rgba(30,30,30, 0.5);	
	-webkit-transition: all 350ms ease-in-out;
	-moz-transition: all 350ms ease-in-out;
	-o-transition: all 350ms ease-in-out;
	-ms-transition: all 350ms ease-in-out;
	transition: all 350ms ease-in-out;
	z-index: 2000;
}

.header.no-bg .navbar {	
	// padding: 20px 0px;
	box-shadow: 0 0;	
} 

/*------------------------------------------*/
/*	 NAVIGATION MENU    
/*------------------------------------------*/

.navbar-nav > li > a {
	
	font-size: 12px;
	line-height: 60px;
	// font-weight: 900;
	display: block;
	padding: 0 12px;
	text-transform: uppercase;
} 

/*------------------------------------------*/
/*	 White / Grey Background
/*------------------------------------------*/

.bg-white .navbar-nav > li > a,
.bg-grey .navbar-nav > li > a { 
	color: $primary; 
}

/*------------------------------------------*/
/*	 Transparent / Dark background
/*------------------------------------------*/

.no-bg .navbar-nav > li > a { 
	padding-top: 18px;
	color: #fff;
	// color:#bdbdbd !important;
}

/*------------------------------------------*/
/*	 Header Download Button
/*------------------------------------------*/

.navbar-nav > li > a.demos,
.navbar-nav > li > a.download {	
	background-color: transparent;
	color: #fff;
	font-size: 13px;
	// font-weight: 900;
	line-height: 22px;
	margin-top: 12px;
	padding: 5px 15px;
	margin-left: 8px;
	border: 2px solid #fff;
	-webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.navbar-nav > li > a.demos,
.bg-grey .navbar-nav > li > a.download,
.navbar-nav > li > a.download:hover {	
	background-color: $secondary;
	border-color: $secondary;
}	

.navbar-nav > li > a.download:hover {
	color: #fff;
}

.navbar-nav > li > a.demos:hover,
.bg-grey .navbar-nav > li > a.download:hover {
	background-color: #444;
	border-color: #444;
	color: #fff;
}

/*------------------------------------------*/
/*	 Navigation Menu Hover
/*------------------------------------------*/

.navbar-nav > li > a:hover {
	color: $secondary;
	background-color: transparent;
}

.navbar-nav > li > a:focus {
	background-color: transparent;
}

.navbar-nav > li > a.demos:focus,
.navbar-nav > li > a.download:focus {
	background-color: $secondary;
	border-color: $primary;
	color: #fff;
}

/*------------------------------------------*/
/*	 Navbar Scroll   
/*------------------------------------------*/

.header.no-bg .navbar.scroll {
	// padding: 0px 0px 40px;
	color: #9e9e9e;
	padding: 0px 0px 18px 0px;
	// background-color: #403e30;
	background-color: #404040;
	box-shadow: 0 0 2px rgba(30,30,30, 0.5);	
}

.header.no-bg .navbar.scroll .navbar-nav > li > a {
	color: $primary;
}

.header.no-bg .navbar.scroll .navbar-nav > li > a.demos,
.header.no-bg .navbar.scroll .navbar-nav > li > a.download {
	background-color: $secondary;
	border-color: $primary;
	color: #fff;
}

.header.no-bg .navbar.scroll .navbar-nav > li > a:hover {
	// color: $secondary;
	color: #fff;
	// color: #404040;

}

.header.no-bg .navbar.scroll .navbar-nav > li > a.demos:hover,
.header.no-bg .navbar.scroll .navbar-nav > li > a.download:hover {
	background-color: #444;
	border-color: #444;
	color: #fff;
}

.header.no-bg .navbar.scroll .he_social:hover {
	color: #fff;
	background-color: #444;
}

/*------------------------------------------*/
/*	 Responsive Menu Button    
/*------------------------------------------*/

.navbar-toggle {
    position: relative;
	background-color: #bdbdbd;
    padding: 10px;
	margin: 25px 15px 25px 0;
	border: none;

	&:hover,&:active,&:focus{
	background-color: #bdbdbd;
	}
}

.no-bg .navbar-toggle,
.bg-white .navbar-toggle {
	color: #444;
	border: 2px solid #444;
}

.no-bg .navbar-toggle .icon-bar,
.bg-white .navbar-toggle .icon-bar {
	background-color:  #444;
}

.logo-white, .logo-black, .logo-small {
	display: block;
}

.header.bg-white .logo-white,
.header.no-bg .navbar .logo-black,
.header.no-bg .navbar .logo-small,
.header.no-bg .navbar.scroll .logo-white,
.header.no-bg .navbar.scroll .logo-small
 {
	display: none;
 }	

.logo{
	max-width: 300px;
	margin: -0.5em;
}



 	@media (max-width: 767px) {
		.logo-small {
				display: block !important;
			}
		
		.logo-black {
			display: none !important;
		}

	@media (max-width: 568px){
		.imgsz {
			 max-width: 200px !important;
			margin: 0;
			}
	}
}

.header.no-bg .navbar.scroll .logo-black {
	display: block;
}

.header.no-bg .navbar.scroll .logo-small {
	display: none;
}

.intro-section {
	background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../img/banner.jpg);	
	width: 100%;
	background-repeat: no-repeat;
	background-position: center center;		
	background-size: cover;
	padding: 140px 0 100px;

	h2, p{
		color: #fff;
	}

	.intro-txt h2 {
		font-size: 54px;
		line-height: 60px;
		// font-weight: 900;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}

	.intro-txt p {
		font-size: 20px;
		line-height: 26px;
		// font-weight: 300;
		padding-bottom: 20px;
	}

	.btnb{
		padding-bottom: 15px;
	}

	.form {	
		background: #a58a6b42;
		border: 1px solid $primary;
		border-radius: 10px;
		padding: 0px;
	}
}

.welcome-section {
	padding: 20px 0px;

	.start-txt {
		font-size: 24px;
	}

	h3, h4 {
		color: #222;
	}

	p {
		color: #444;
    	font-size: 15px;
    	line-height: 23px;	
	}

	.welcome-quote h3 {
		padding-bottom: 10px;
	}

	.welcome-txt p {
		padding-bottom: 10px;
	}

	.slogan-txt{
		padding-bottom: 10px;
	}
}

.about-section {
	background-color: #f7f7f4;
	padding: 20px 0px;

	h4 {
	    color: $primary;
	    font-size: 30px;
	    line-height: 15px;
	    // font-weight: 900;
	    // text-transform: uppercase;
	    letter-spacing: 1px;
	    margin-bottom: 15px;
	}

	p {
    	color: #444;
    	font-size: 15px;
    	line-height: 23px;
	}
}

.content-section {

	.cbox-top {
		margin: 40px 0 30px;
	}

	.cbox-in {
		margin-bottom: 30px;
	}
	
	.cbox span {
		color: $primary;
		font-size: 32px;
		line-height: 32px!important;
		float: left;
		margin-right: 15px;	
	}

	.cbox h4 {
		line-height: 32px!important;
		margin-bottom: 10px;
	}

	.content-2-img { 
		background-image: url(../img/img2.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		padding: 350px 0px;	
	}
}

.portfolio-section {
	
	.portfolio-items-list {
		margin: 0;
		padding: 0
	}

	.portfolio-item {
		position: relative;	
		padding: 0;
	}

	.portfolio-item a {
		cursor: url(../images/icons/pointer.png),crosshair;
	}

	.portfolio-item .hover-overlay {
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}

	.portfolio-item .hover-overlay img {
		-webkit-transition: all 600ms;
		-moz-transition: all 600ms;
		-o-transition: all 600ms;
		transition: all 600ms;
	} 

	.portfolio-item .overlay-content {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 20;
		width: 100%;
		zoom: 1;
		opacity: 0;
		-webkit-transition: all 200ms ease-out;
		-moz-transition: all 200ms ease-out;
		-o-transition: all 200ms ease-out;
		transition: all 200ms ease-out;
	}

	.portfolio-item .overlay-content h4 {
		color: #fff;
		font-size: 30px;
		line-height: 17px;
		// text-transform: uppercase;
		margin-bottom: 8px;
		padding-left: 20px;
	}

	.portfolio-item .overlay-content p {
		color: #fff;
		font-size: 15px;
		line-height: 20px;
		padding: 0 20px;
	}

	.portfolio-item .item-overlay {
		opacity: 0;
		-moz-opacity: 0; 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(10, 10, 10, 0.75);
		-moz-transition: opacity 400ms ease-out;
		-webkit-transition: opacity 400ms ease-out;
		-o-transition: opacity 400ms ease-out;
		transition: opacity 400ms ease-out;
	}

	.portfolio-item .hover-overlay:hover > img {
		transform: scale(1.2);
		-ms-transform: scale(1.2);
		-webkit-transform: scale(1.2);
		-o-transform: scale(1.2);
		-moz-transform: scale(1.2);
	}

	.portfolio-item .hover-overlay:hover .item-overlay {
		opacity: 1; 
		-moz-opacity: 1; 
	}

	.portfolio-item:hover .overlay-content {
		bottom: 10%;
		opacity: 1; 
		-moz-opacity: 1; 
	}
}

.footer-section {
	background-image: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)),url(../img/lower-banner.jpg);
    background-repeat: no-repeat;
    background-position: 60% 50%;
    background-size: cover;
	padding: 250px 0px;


	@media (max-width: 991px){
		padding: 150px 0px;
	}

	@media (max-width: 768px){
		padding: 100px 0px;
	}


	@media (max-width: 568px){
		padding: 50px 0px;
	}

	h2, p{
		color: #fff;
	}

	h2 {
		padding-bottom: 25px;
	}


}


footer {

    padding: 40px 0px 20px;
    // background: #403e30;
    background: #404040;
    color: $footerLinks;
    font-size: 13px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 13px;
        @media (max-width: 990px) {
            font-size: 12px;
        }
        &:hover {
             color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
    	 color: $footerLinks;
        font-size: 13px;
        @media (max-width: 990px) {
            font-size: 12px;
        }
    }
}

/* 
   ------------------------------------------------------
	RESPONSIVE STYLES
   ------------------------------------------------------
*/

@media (min-width: 1440px) and (max-width: 2880px) {
	.portfolio-item .overlay-content p { font-size: 17px; line-height: 25px; 
		// font-weight: 300; 
		padding: 0 20px; }
}

@media (min-width: 992px) and (max-width: 1199px) { 
	h4.h4-lg { font-size: 18px; line-height: 24px; }
	h4 { font-size: 17px; line-height: 17px; margin-bottom: 10px; }
	h3, h3.h3-thin { font-size: 26px; line-height: 32px; }
	p.p-lg { font-size: 18px; line-height: 26px; } 
	.intro-txt h2 { font-size: 45px; line-height: 48px; }
	.intro-txt p { font-size: 18px; line-height: 24px; }	
	.portfolio-item .overlay-content h4 { font-size: 16px; line-height: 16px; }
}

@media only screen and (max-width: 991px) {
	h4.h4-lg { font-size: 18px; line-height: 24px; }
	h4 { font-size: 17px; line-height: 17px; margin-bottom: 10px; }
	h3, h3.h3-thin { font-size: 26px; line-height: 32px; }
	p.p-lg { font-size: 18px; line-height: 26px; } 
	.navbar-nav > li > a { padding: 0 8px; }
	.intro-txt h2 { font-size: 45px; line-height: 49px; }	
	.intro-txt p { font-size: 18px; line-height: 24px; }
}

@media only screen and (max-width: 767px) {
	.header.no-bg { margin-bottom: 60px; }
	.navbar-brand { padding: 22px 30px; }
	.navbar-nav { background: #404040; margin-top: 0; margin-bottom: 0; border-top: 1px solid #ddd; }	
	.navbar-nav > li { text-align: center; }
	.navbar-nav > li > a { color: #404040; line-height: 30px; text-align: center; }
	.navbar-nav > li > p, .navbar-nav > li > p i, .navbar-nav > li > p a i { line-height: 45px; margin-left: 0; }
	.navbar-nav > li > a.demos, .navbar-nav > li > a.download {	margin-top: 0px; margin-left: 0; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; }
	.header.no-bg .navbar { padding: 0; background-color: #404040; min-height: 70px;}	
	.header.no-bg .navbar .logo-white { display: none; }
	.header.no-bg .navbar .logo-black { display: block; }
	.header.no-bg .navbar .logo-small { display: none; }
	.header.no-bg .navbar.scroll {padding: 0px 0px 0px 0px !important;}
	.no-bg .navbar-nav > li > a { color: #c83132; padding-top: 0px;}
	.no-bg .navbar-nav > li > a:hover { color: #fff; background-color: transparent; }
	.no-bg .navbar-nav > li > a.demos, .no-bg .navbar-nav > li > a.download { background-color: #00c1f0; border-color: #00c1f0; color: #fff; }
	.no-bg .navbar-nav > li > a.demos:hover, .no-bg .navbar-nav > li > a.download:hover { background-color: #444; border-color: #444; color: #fff; }
	.intro-txt h2 { font-size: 40px; line-height: 43px; margin-bottom: 10px; }
	.intro-txt p { font-size: 18px; line-height: 22px; padding: 0 8%; }
}

@media only screen and (max-width: 640px) {
	.intro-txt h2 { font-size: 38px; line-height: 41px; }
	.intro-txt p { font-size: 17px; line-height: 22px; padding: 0; }
}

@media only screen and (max-width : 568px) {
	h4.h4-lg { font-size: 20px; line-height: 17px; }
	.btnb{
		text-align: center;
	}	
	.btn {
		 font-size: 12px;
		// font-weight: 500; 
		padding: 9px 20px; margin-right: 5px; }
	.btn.btn-lg { font-size: 12px; 
		// font-weight: 700; 
		padding: 9px 20px; }
	.navbar-brand { padding: 22px 15px; }
	.navbar-toggle { margin: 14px 13px 19px 0;}
	.intro-txt h2 { font-size: 32px; line-height: 35px; }
	.intro-txt p { font-size: 16px; line-height: 22px; }
	.slogan-txt h4.h4-lg { font-size: 19px; line-height: 25px; }	
}

@media (max-width: 320px) {	
	h4.h4-lg { font-size: 17px; line-height: 23px; }
	h4 { font-size: 16px; line-height: 16px; margin-bottom: 10px; }
	h3, h3.h3-thin, .section-title-thin h3, .section-title h3 { font-size: 20px; line-height: 24px; }
	p.p-lg { font-size: 16px; line-height: 23px; }
	.btn { margin-top: 8px; margin-right: 0; }
	.intro-txt h2 { font-size: 26px; line-height: 30px; }
	.intro-txt p { font-size: 15px; line-height: 20px; }
	.portfolio-item .overlay-content h4 { font-size: 15px; line-height: 15px; }
}